<div class="row animated fadeIn">

    <div class="col-md-6">
        <div class="card card-body">
            <h3 class="box-title m-b-0">Boletin: titulo</h3>
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    <form #f="ngForm" ngNativeValidate (ngSubmit)="guardarBoletin(f)">
                        <div class="form-group">
                            <label>Titulo del boletin</label>
                            <input [(ngModel)]="boletin.titulo" name="titulo" type="text" class="form-control" placeholder="Titulo" required>
                        </div>

                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea [(ngModel)]="boletin.descripcion" name="descripcion" type="text" class="form-control" rows="5" required></textarea>

                        </div>


                        <button type="submit" class="btn btn-success waves-effect waves-light m-r-10">
                          <i class="fa fa-save"></i>
                          Guardar
                        </button>
                        <a routerLink="/dashboard/boletines" class="btn btn-inverse waves-effect waves-light">Cancelar</a>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card card-body">

            <!--<h4 class="box-title m-b-0">Hospital</h4>
            <img [src]="hospital.img | imagen:'hospital'" class="rounded img-fluid">-->
        </div>
    </div>

    <!--<div class="col-md-3" *ngIf="medico._id">
        <div class="card card-body">
            <h4 class="box-title m-b-0">Fotografía</h4>

            <img (click)="cambiarFoto()" [src]="medico.img | imagen:'medico'" class="img-circle img-fluid pointer">
        </div>
    </div>-->

</div>